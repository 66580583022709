/* spell-checker: disable */

import React, { memo } from "react";
import styled, { keyframes } from "styled-components";
import { shuffle } from "lodash";

import { MainLayout } from "components/Layout/MainLayout";

/**
 * NOTE: Please add your GitHub username to the names array in the alphabetical order.
 * GitHub のユーザー名を辞書順に names 配列に追加してください。
 */
const names = [
  "0225kazuki",
  "0tany",
  "akirato0223",
  "amura870",
  "Arsene150",
  "AtsushiKitano",
  "blueglasses1995",
  "cloudtion",
  "cocomode18",
  "d-tsutsumi",
  "endlessn",
  "HagaSpa",
  "hayashi7752",
  "hironorikawakami",
  "hiroyaiizuka",
  "hiroyatanifuji",
  "hkoichi",
  "hmochizuki",
  "hotter6163",
  "Itooka",
  "Jauhieni",
  "k-takahashi23",
  "karszawa",
  "kimujun",
  "koffe0522",
  "kojitakemoto",
  "lightnet328",
  "Lumonde-software",
  "marina-ki",
  "Masayuki0927",
  "minami-minami",
  "NguyenAnhVuong",
  "oda0",
  "odanado",
  "pakugoku",
  "pranc1ngpegasus",
  "puremoru",
  "REI31001",
  "reo777",
  "sadnessOjisan",
  "shibatani",
  "shimada",
  "shinya2020",
  "sohenze",
  "sottar",
  "sugarshin",
  "swen128",
  "t-i-0414",
  "ta21cos",
  "tadokoro-ryusuke",
  "takaaa220",
  "takashimamorino",
  "Takayuki-Hasegawa",
  "takeru0001",
  "tomo-ch-dev",
  "tomo-Y2002",
  "tsugitta",
  "urahiroshi",
  "whatasoda",
  "y-zumi",
  "y0ssh1",
  "Yuichi-Kadota",
  "YutorinX",
  "yuyamakiyama",
];

const shuffledNames = shuffle(names);

const seed = Date.now();

const random = (x: number) => ((x ^= x << 13), (x ^= x >> 17), (x ^= x << 5), x >>> 0);

const colors = ["red", "orange", "yellow", "green", "blue", "indigo", "violet"];

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const rainbow = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
`;

const ThankYouText = styled.strong`
  font-size: 10rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet, red);
  background-size: 200% 200%;
  background-clip: text;
  color: transparent;
  animation: ${rainbow} 3s linear infinite;
`;

const scroll = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translateX(150%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

const ScrollingText = styled.div<{ delay: number; duration: number; color: string }>`
  color: ${({ color }) => color};
  font-size: 2rem;
  white-space: nowrap;
  animation: ${scroll} linear infinite;
  animation-delay: ${({ delay }) => -delay}s;
  animation-duration: ${({ duration }) => duration}s;
`;

export const Contributors = memo(() => (
  <MainLayout title="Contributors">
    <Container>
      <ThankYouText>THANK YOU!</ThankYouText>
      <ScrollWrapper>
        {shuffledNames.map((name, i) => (
          <ScrollingText
            delay={random(seed + i)}
            key={name}
            duration={20 + (random(seed + i) % 10)}
            color={colors[random(seed + i) % colors.length] ?? "white"}
          >
            {name}
          </ScrollingText>
        ))}
      </ScrollWrapper>
    </Container>
  </MainLayout>
));
